import React, { Component } from 'react';
import axios from 'axios';
import API_URL from '../../constants';
import LogoTelkom from './../../assets/images/LogoTelkom.png';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/id';
import html2pdf from 'html2pdf.js';

moment.locale('id');

class DownloadPdfAcaraComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: null,
            allDatas: [],
            room: '',
            isDownloading: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        let currentPage = 1;
        let pageSize = 100;

        try {
            this.setState({ isLoading: true });

            let aggregatedData = [];

            while (true) {
                const response = await axios({
                    url: `${API_URL}aggregation/check-in-tracker/room-checkins?roomId=${this.props.match.params.roomId}&buildingName=${this.props.match.params.meetingTitle}&limit=${pageSize}&page=${currentPage}&startDate=${this.props.match.params.startDate}&endDate=${this.props.match.params.endDate}`,
                    method: 'GET',
                    auth: {
                        username: 'mytelu',
                        password: 'N0thing_s3cure',
                    },
                });

                const { data } = response.data;

                if (data.length > 0) {
                    aggregatedData = [...aggregatedData, ...data];
                    currentPage++;
                } else {
                    break;
                }
            }

            if (aggregatedData.length > 0) {
                const sortedData = aggregatedData.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });

                this.setState({
                    allDatas: sortedData,
                    room: sortedData[0].Room.name,
                });
            } else {
                this.setState({ allDatas: [], room: '' });
                Swal.fire({
                    title: 'Belum Ada Peserta.',
                    type: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#D32C28',
                }).then((result) => {
                    if (result.value) {
                        window.close();
                    }
                });
            }

            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false });
            console.log(error);
        }
    };

    // getData = async () => {
    //     try {
    //         this.setState({ isLoading: true });

    //         await axios({
    //             url: `${API_URL}aggregation/check-in-tracker/room-checkins?roomId=${this.props.match.params.roomId}&buildingName=${this.props.match.params.meetingTitle}&limit=99999&startDate=${this.props.match.params.startDate}&endDate=${this.props.match.params.endDate}`,
    //             method: 'GET',
    //             auth: {
    //                 username: 'mytelu',
    //                 password: 'N0thing_s3cure',
    //             },
    //             timeout: 120000,
    //         }).then((res) => {
    //             if (res.data.data.length > 0) {
    //                 const sortedData = res.data.data.sort((a, b) => {
    //                     return new Date(a.createdAt) - new Date(b.createdAt);
    //                 });

    //                 this.setState({
    //                     allDatas: sortedData,
    //                     room: sortedData[0].Room.name,
    //                 });
    //             } else {
    //                 this.setState({ allDatas: [], room: '' });
    //                 Swal.fire({
    //                     title: 'Belum Ada Peserta.',
    //                     type: 'warning',
    //                     confirmButtonText: 'OK',
    //                     confirmButtonColor: '#D32C28',
    //                 }).then((result) => {
    //                     if (result.value) {
    //                         window.close();
    //                     }
    //                 });
    //             }

    //             this.setState({ isLoading: false });
    //         });
    //     } catch (error) {
    //         // if(error.response.status === 403){
    //         //     this.sweetAlertHandler()
    //         // }
    //         // this.setState({ isLoading: false });
    //         this.setState({ isLoading: false });
    //         console.log(error);
    //     }
    // };

    generatePdf = () => {
        const element = document.getElementById('pdf-content');
        const fileName = this.props.match.params.meetingTitle;

        const opt = {
            margin: 12,
            filename: `Presensi_${fileName}.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                width: 1010,
                dpi: 300,
                scale: 5,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        };

        this.setState({ isDownloading: true });

        const chunkSize = 200;
        const totalChunks = Math.ceil(this.state.allDatas.length / chunkSize);

        const generateChunk = (chunkIndex) => {
            const chunkData = this.state.allDatas.slice(
                chunkIndex * chunkSize,
                (chunkIndex + 1) * chunkSize
            );
            const elementClone = element.cloneNode(true);
            const tableBody = elementClone.querySelector('#table3');
            tableBody.innerHTML = '';

            chunkData.forEach((data, i) => {
                const row = document.createElement('tr');
                row.innerHTML = `
                    <td class="text-center">${i +
                        1 +
                        chunkIndex * chunkSize}</td>
                    <td>${data.createdBy}</td>
                    <td class="text-center">${moment(data.createdAt).format(
                        'DD MMMM YYYY, HH:mm:ss'
                    )} WIB</td>
                    <td class="text-center">${data.status}</td>
                `;
                tableBody.appendChild(row);
            });

            html2pdf()
                .set(opt)
                .from(elementClone)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    if (chunkIndex + 1 < totalChunks) {
                        pdf.addPage();
                        generateChunk(chunkIndex + 1);
                    } else {
                        pdf.save(`Presensi_${fileName}.pdf`);
                        this.setState({ isDownloading: false });
                    }
                });
        };

        generateChunk(0);
    };

    // generatePdf = () => {
    //     const element = document.getElementById('pdf-content');
    //     const ignoreElements = document.getElementById('dl-pdf');
    //     const fileName = this.props.match.params.meetingTitle;

    //     const opt = {
    //         margin: 12,
    //         filename: `Presensi_${fileName}.pdf`,
    //         image: { type: 'jpeg', quality: 1 },
    //         html2canvas: {
    //             width: 1010,
    //             dpi: 300,
    //             scale: 2,
    //             letterRendering: true,
    //             useCORS: true,
    //             ignoreElements: ignoreElements,
    //         },
    //         jsPDF: {
    //             unit: 'mm',
    //             format: 'a4',
    //             orientation: 'portrait',
    //             ignoreElements: ['.button', '#dl-pdf'],
    //             margin: 10,
    //         },
    //     };

    //     html2pdf()
    //         .set(opt)
    //         .from(element)
    //         .save();
    // };

    render() {
        return (
            <div>
                <style>
                    {`
                        #navbar {
                            position: fixed;
                            top: 0;
                            width: 100%;
                            height: 50px;
                            background-color: #282c34;
                            z-index: 1;
                        }

                        #container-pdf {
                            width: 1040px;
                            padding: 20px;
                        }

                        table {
                            width: 1000px;
                            color: #000;
                        }

                        table,
                        th,
                        td {
                            border: 1px solid grey;
                            border-collapse: collapse;
                            padding: 10px;
                            font-size: 12pt;
                            border-color: #000;
                        }

                        button {
                            float: right;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 180px;
                            height: 50px;
                            background-color: #EF303B;
                            color: white;
                            font-size: 16px;
                            padding: 0 10px;
                            border: none;
                            text-align: center;
                        }

                        button #title {
                            flex: 80%;
                            display: grid;
                            place-items: center;
                        }

                        button #icon {
                            flex: 20%;
                        }

                        button:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }

                        @media only screen and (min-width: 1046px) {
                            #container-pdf {
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%, 0%);
                            }
                        }

                    `}
                </style>
                <div>
                    {this.state.isLoading ? (
                        <Modal
                            show={this.state.isLoading}
                            onHide={() => this.setState({ isLoading: false })}
                            centered
                        >
                            <Modal.Body className="text-center">
                                <h4>Harap Tunggu...</h4>
                                <div
                                    className="text-center spinner-border text-danger "
                                    role="status"
                                    style={{ width: '5rem', height: '5rem' }}
                                ></div>
                            </Modal.Body>
                        </Modal>
                    ) : this.state.allDatas.length < 1 ? (
                        <div className="container vh-100 d-flex justify-content-center align-items-center">
                            Belum Ada Peserta.
                        </div>
                    ) : (
                        <div>
                            <div id="navbar">
                                <button
                                    id="dl-pdf"
                                    onClick={this.generatePdf}
                                    data-html2canvas-ignore="true"
                                    disabled={this.state.isDownloading}
                                >
                                    <div id="title">
                                        {this.state.isDownloading
                                            ? 'Downloading...'
                                            : 'Download PDF'}
                                    </div>
                                    {!this.state.isDownloading && (
                                        <i
                                            id="icon"
                                            className="feather icon-download"
                                            data-feather="download"
                                            style={{
                                                color: 'white',
                                                width: '20px',
                                            }}
                                        ></i>
                                    )}
                                </button>
                            </div>

                            <div
                                id="checkpoint"
                                style={{ height: '50px' }}
                            ></div>

                            <div id="container-pdf">
                                <div
                                    id="pdf-content"
                                    style={{
                                        fontFamily:
                                            'Times New Roman, Times, serif',
                                    }}
                                >
                                    <table className="tg">
                                        <colgroup>
                                            <col style={{ width: '250px' }} />
                                            <col style={{ width: '400px' }} />
                                            <col style={{ width: '175px' }} />
                                            <col style={{ width: '175px' }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <td
                                                    className="tg-0pky"
                                                    rowspan="4"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <img
                                                        id="logo-telkom"
                                                        src={LogoTelkom}
                                                        alt="Image"
                                                        width="200"
                                                    />
                                                </td>
                                                <td className="tg-c3ow">
                                                    UNIVERSITAS TELKOM
                                                </td>
                                                <td className="tg-0pky">
                                                    No. Formulir
                                                    <br />
                                                </td>
                                                <td className="tg-0pky"></td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="tg-c3ow"
                                                    rowspan="2"
                                                >
                                                    Jl. Telekomunikasi No. 1
                                                    Terusan Buah Batu
                                                    <br />
                                                    Bandung 40257
                                                </td>
                                                <td className="tg-0pky">
                                                    Revisi
                                                </td>
                                                <td className="tg-0pky"></td>
                                            </tr>
                                            <tr>
                                                <td className="tg-0pky">
                                                    Berlaku Efektif
                                                    <br />
                                                </td>
                                                <td className="tg-0pky"></td>
                                            </tr>
                                            <tr>
                                                <td className="tg-c3ow">
                                                    Daftar Hadir
                                                </td>
                                                <td className="tg-0pky">
                                                    Hal.
                                                </td>
                                                <td className="tg-0pky"></td>
                                            </tr>
                                        </thead>
                                    </table>

                                    <br />

                                    <table className="tg">
                                        <colgroup>
                                            <col style={{ width: '200px' }} />
                                            <col style={{ width: '800px' }} />
                                        </colgroup>
                                        <tbody id="table2">
                                            <tr>
                                                <td>Hari, Tanggal</td>
                                                <td>
                                                    {moment(
                                                        this.props.match.params
                                                            .startDate
                                                    ).format(
                                                        'dddd, DD MMMM YYYY, HH:mm'
                                                    ) + ' WIB'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Agenda</td>
                                                <td>
                                                    {
                                                        this.props.match.params
                                                            .meetingTitle
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tempat</td>
                                                <td>{this.state.room}</td>
                                            </tr>
                                            <tr>
                                                <td>Waktu</td>
                                                <td>
                                                    {moment(
                                                        this.props.match.params
                                                            .startDate
                                                    ).format(
                                                        'DD MMMM YYYY, HH:mm'
                                                    ) +
                                                        ' WIB' +
                                                        ' - ' +
                                                        moment(
                                                            this.props.match
                                                                .params.endDate
                                                        ).format(
                                                            'DD MMMM YYYY, HH:mm'
                                                        ) +
                                                        ' WIB'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br />

                                    <table className="tg">
                                        <colgroup>
                                            <col style={{ width: '50px' }} />
                                            <col style={{ width: '400px' }} />
                                            <col style={{ width: '275px' }} />
                                            <col style={{ width: '275px' }} />
                                        </colgroup>
                                        <thead className="text-center">
                                            <tr>
                                                <th className="tg-0lax">No.</th>
                                                <th className="tg-0lax">
                                                    Nama
                                                </th>
                                                <th className="tg-0lax">
                                                    Tanggal Checkin
                                                </th>
                                                <th className="tg-0lax">
                                                    Status Checkin
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="table3">
                                            {this.state.allDatas.map(
                                                (data, i) => (
                                                    <tr key={data.id}>
                                                        <td className="text-center">
                                                            {i + 1}
                                                        </td>
                                                        <td>
                                                            {data.createdBy}
                                                        </td>
                                                        <td className="text-center">
                                                            {moment(
                                                                data.createdAt
                                                            ).format(
                                                                'DD MMMM YYYY, HH:mm:ss'
                                                            ) + ' WIB'}
                                                        </td>
                                                        <td className="text-center">
                                                            {data.status}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DownloadPdfAcaraComponent;
