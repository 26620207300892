import React from 'react';
import LogoTelkom from '../../assets/images/LogoTelkom.png';
import { createDecipheriv } from 'crypto';
import { withRouter } from 'react-router-dom';

class ShowKtm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            decryptedText: {},
            decryptStatus: false,
            statusText: 'Loading...',
        };
    }

    componentDidMount() {
        this.decryptKTMId(this.props.match.params.encryptedData);
    }

    decryptKTMId = async (encryptText) => {
        try {
            const [value, iv] = encryptText.split('_');

            const key = Buffer.from(
                'dd5581039bc43f616a978d3d8118486df1c0f45772c3195d542c3a1637db1bbc',
                'hex'
            );

            const ivBuffer = Buffer.from(iv, 'hex');

            const decipher = createDecipheriv('aes-256-ctr', key, ivBuffer);

            let decrypted = decipher.update(value, 'hex', 'utf8');
            decrypted += decipher.final('utf8');

            const cleanedData = decrypted.replace(
                /[\u0000-\u001F\u007F-\u009F]/g,
                ''
            );

            const descryptedJsonString = JSON.parse(cleanedData);

            this.setState({
                decryptedText: descryptedJsonString,
                decryptStatus: true,
            });

            // return decrypted;
        } catch (err) {
            // console.error('Error decrypting:', err);
            console.error('ERROR.');
            this.setState({
                statusText: 'Error.',
            });
            throw err; // Rethrow the error for handling elsewhere
        }
    };

    render() {
        return (
            <>
                <div className="container vh-100 d-flex justify-content-center align-items-center">
                    {this.state.decryptStatus ? (
                        <div
                            id="ktm"
                            className="card text-center"
                            style={{ width: '350px' }}
                        >
                            <div className="p-3">
                                <img
                                    src={LogoTelkom}
                                    class="card-img-top mb-2"
                                    alt="..."
                                    style={{ width: '60%' }}
                                ></img>
                                <h5
                                    style={{
                                        letterSpacing: '10px',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {this.state.decryptedText.faculty}
                                </h5>
                                <h5>
                                    {this.state.decryptedText.study_program}
                                </h5>
                            </div>

                            <div className="container p-3 bg-danger text-light rounded-bottom">
                                <div className="row">
                                    <div className="col-5 rounded-3">
                                        <img
                                            src={this.state.decryptedText.photo}
                                            className="card-img-top"
                                            alt="..."
                                            style={{
                                                width: '100%',
                                                borderRadius: '6px',
                                            }}
                                        />
                                    </div>
                                    <div className="col-7">
                                        <div className="row">
                                            <div className="col-12 pl-0 text-left">
                                                <span>Nama</span>
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: 'bolder',
                                                    }}
                                                >
                                                    {
                                                        this.state.decryptedText
                                                            .name
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-12 pl-0 text-left">
                                                <br />
                                                <span>
                                                    Nomor Induk Mahasiswa
                                                </span>
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: 'bolder',
                                                    }}
                                                >
                                                    {
                                                        this.state.decryptedText
                                                            .number_id
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-12 pl-0 text-left">
                                                <br />
                                                <span>Kelas</span>
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: 'bolder',
                                                    }}
                                                >
                                                    {
                                                        this.state.decryptedText
                                                            .class
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-5 text-left">
                                        <br />
                                        <span>Angkatan</span>
                                        <br />
                                        <span style={{ fontWeight: 'bolder' }}>
                                            {this.state.decryptedText.class_of}
                                        </span>
                                    </div>
                                    <div className="col-7 pl-0 text-left">
                                        <br />
                                        <span>Status</span>
                                        <br />
                                        <span class="bg-primary px-2 py-1 text-light rounded-pill mt-2 d-inline-block">
                                            {this.state.decryptedText.status}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>{this.state.statusText}</div>
                    )}
                </div>

                <style jsx>{`
                    * {
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                    }
                `}</style>
            </>
        );
    }
}

export default withRouter(ShowKtm);
