import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';
import ShowKtm from '../Demo/ShowKtm/ShowKtm';
import DownloadPdfAcara from '../Demo/ManagementRapat/DownloadPdfAcara';
import '../App.css';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                />
            ) : null;
        });

        return (
            <>
                <Aux>
                    <ScrollToTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route
                                    path="/show-ktm/:encryptedData"
                                    component={ShowKtm}
                                />
                                <Route
                                    path="/download-pdf-acara/:roomId/:startDate/:endDate/:meetingTitle"
                                    component={DownloadPdfAcara}
                                />
                                {menu}
                                <Route path="/" component={AdminLayout} />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </Aux>
            </>
        );
    }
}

export default App;
