import React from "react";

const Login = React.lazy(() => import("./Demo/Authentication/SignIn/Login"));
const LoginSurvey= React.lazy(() => import("./Demo/Authentication/SignIn/LoginSurvey"));

const route = [
  { path: "/auth/login", exact: true, name: "Login", component: Login },
  { path: "/survey/login", exact: true, name: "LoginSurvey", component: LoginSurvey },
];

export default route;
